interface ComingSoonBadgeProps {
    badgeClass?: string;
}

export default function ComingSoonBadge({
    badgeClass = "text-bg-light border",
}: ComingSoonBadgeProps) {
    return (
        <span className="small">
            <span className={`badge ${badgeClass}`}>Coming Soon</span>
        </span>
    );
}
